<template>
    <AppIcon
        v-bind="$props"
        :class="[ $style['menu-arrow-down'], { [$style['menu-arrow-down--rotate']]: $attrs['rotate'] } ]"
    >
        <template #default="{ color }">
            <path
                d="M7 10L12 15L17 10H7Z"
                :fill="color"
            />
        </template>
    </AppIcon>
</template>

<style
    lang="sass"
    module
    scoped
>
    .menu-arrow-down
        transition: transform 100ms var(--transition-default-ease)
        will-change: transform

        &--rotate
            transform: rotate(0.5turn)
</style>
